@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;  
}

:root {
  --pink: #EF1649;
  --purple: #151337;
  --grey:#F5F5F5;
  --textGrey:#7E8287;
  --blue:#1090d0;
--textPurple: #292751;
--lightblue: #F1F8FC;
--lightpink: '#FEF1F4';
--homegrey:'#535353';
--homeblack: '#333333';
--middleblue:#32BBFF;
--pinkdarker:#DA103F;
--bluedarker:'#045e8b';
}

@layer utilities {  
  .text-balance {
    text-wrap: balance;
  }
}

@media (min-width: 1600px) { /* 3xl */
  .xl3{
   width: 75%; /* Red */
  }
}

/* body {
  font-family: 'Inter' !important;
} */

.meeting-btn {
  position: relative;
  overflow: hidden;
  color: var(--pink);
  
  /* padding: 10px 20px; */
  cursor: pointer;
  border: 2px solid var(--pink);
  border-radius: 9999px; /* Full rounded */
  z-index: 1; /* Ensure the button is on top */
}

.meeting-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: var(--pink);
  transition: width 0.3s ease;
  z-index: -1; /* Ensure the background is behind the text */
}

.meeting-btn:hover::after {
  width: 100%; /* Expand background to full width */
}

.meeting-btn .meeting-text {
  position: relative;
  z-index: 2; /* Make sure text stays on top of the background */
  transition: transform 0.3s ease, color 0.3s ease;
}

.meeting-btn:hover .meeting-text {
  transform: translateX(10px);
  color: white !important;
}

.meeting-btn1 {
  position: relative;
  overflow: hidden;
  color: white;
  background-color: var(--pink);
  
  /* padding: 10px 20px; */
  cursor: pointer;
  border: 2px solid var(--pink);
  border-radius: 9999px; /* Full rounded */
  z-index: 1; /* Ensure the button is on top */
}

.meeting-btn1 .meeting-text {
  position: relative;
  z-index: 2; /* Make sure text stays on top of the background */
  transition: transform 0.3s ease, color 0.3s ease;
}

.meeting-btn1:hover .meeting-text {
  transform: translateX(10px);
  color: var(--pink) !important;
}

.meeting-btn1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: white;
  transition: width 0.3s ease;
  z-index: -1; /* Ensure the background is behind the text */
}

.meeting-btn1:hover::after {
  width: 100%; /* Expand background to full width */
}

.slick-slide {
  display: flex !important;
  flex-direction: row!important;
  justify-content: center;
  align-items: center;
}

.slick-list {
  overflow: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; 
  box-shadow: 0 0 0 1000px white inset !important; 
  background-color: white !important; 
}

/* HTML: <div class="loader"></div> */ 
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#2da9da 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#2da9da);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13{ 
  100%{transform: rotate(1turn)}
}


.sticky-wrapper {
  overflow: visible; /* Ensure parent does not interfere */
}

.fixed-video-size {
  max-height: 500px;
  
  /* Full width responsiveness */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;

  }
  
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  
  body {
    @apply bg-background text-foreground;
  }
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}

.loader,
.loader::before,
.loader::after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #1090d0 96%,#0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #1090d0 96%,#0000) 0 50%/80% 20% repeat-x;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}

.loader::after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}

.bg_rev{
  background-image: url("/images/revenue-bg.webp") !important;
}

h1{
  font-size: 54px!important;
  font-weight: 900!important;
  line-height: 120%;
  
}

h2{
  font-size:38px!important;
  font-weight: 900!important;
  
  /* color: #333333; */
}

h3{
  font-size: 22px !important;
  font-weight: 700;
  
  /* color: #333333;  */
}

h4{
  font-size: 22px !important;
  font-weight: 700;
  
  /* color: #333333; */

}

h4,h5,h6{
  font-size: 20px !important;
  font-weight: 700;
  
  /* color: #333333; */
}

p {
  font-size: 20px; /* 16px */
  font-weight: 400; /* Regular */
}

li{
      font-size: 18px; 
    }	
@media (max-width: 1450px)
{
  h1{
    font-size: 38px!important;
    /* line-height: 100%; */
  }
  
  h2{
    font-size:30px!important;
  }
  
  h3{
    font-size: 22px!important;
  }
  
  h4,h5,h6{
    font-size: 18px !important;
    font-weight: 700;
  
  }
  
  p {
    font-size: 16px; /* 16px */
    font-weight: 400; /* Regular */
  }
  li {
    font-size: 16px; /* 16px */
    font-weight: 400; /* Regular */
  }
}
@media (max-width: 1050px)
{
  h1{
    font-size: 28px!important;
    line-height: normal;
  }
  
  h2{
    font-size:24px!important;
  }
  
  h3{
    font-size: 20px! important;
  }
  
  h4,h5,h6{
    font-size: 18px !important;
    font-weight: 700;
  
  }
  
  p {
    font-size: 16px; /* 16px */
    font-weight: 400; /* Regular */
  }

  li{
        font-size: 16px; 
    }
  
    .vef_btn{
    padding: 10px !important;
    font-size: 14px!important;
  }
}


@media (max-width: 650px)
{
  h1{
    font-size: 26px!important;
    line-height: normal;
  }
  
  h2{
    font-size:22px!important;
  }
  
  h3{
    font-size: 18px! important;
  }
  
  h4,h5,h6{
    font-size: 16px !important;
    font-weight: 700;
  
  }
  
  p {
    font-size: 14px; /* 16px */
    font-weight: 400; /* Regular */
  }

  li{
        font-size: 14px; 
    }
  
    .vef_btn{
    padding: 10px !important;
    font-size: 14px!important;
  }
 
}

.vef_bg {
  margin-top: 30px;
  color: white !important;
  background-color: var(--blue);
  border-radius: 20px !important;
}

.vef_btn {
  background-color: var(--pink);
  padding: 15px 20px !important;
  color: white !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.vef_btn strong{
  color: white !important;
}

.box {
color: white;
  
--border-angle: 0turn; 
  --main-bg: conic-gradient(
      from var(--border-angle),
      #213,
      #112 5%,
      #112 60%,
      #213 95%
    );
  
  border: solid 5px transparent;
  border-radius: 2em;
  
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
  
  background: 
    var(--main-bg) padding-box,
    var(--gradient-border) border-box, 
    var(--main-bg) border-box;
  background-position: center center;
  animation: bg-spin 3s linear infinite;
  
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.blog-img img {
  height: auto !important; /* Ensure the height scales proportionally */
  max-width: 100%; /* Restrict the image width to the container */
  display: block; /* Ensure it's treated as a block-level element for centering */
  margin: 0 auto; /* Center horizontally */
}

.smooth3{
  transition: ease-in-out all 0.3s;
}

@keyframes shake {
  0%, 100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(-15deg);
  }

  75% {
    transform: rotate(10deg);
  }
}

@keyframes zigzag {
  0% {
    transform: translateY(0) translateX(0);
  }

  25% {
    transform: translateY(20vh) translateX(-10px);
  }

  50% {
    transform: translateY(40vh) translateX(10px);
  }

  75% {
    transform: translateY(60vh) translateX(-10px);
  }

  100% {
    transform: translateY(80vh) translateX(0);
  }
}

.animate-wavy {
  animation: zigzag 20s ease-in-out infinite;
}

@layer utilities {
  .redirect-to-app_w3era-mobile {
      @apply transition-transform sm:hidden font-semibold duration-300 group-hover:-translate-x-2;
  }

  .redirect-to-app_w3era-laptop {
      @apply transition-transform hidden sm:block font-semibold duration-300 group-hover:-translate-x-2;
  }

.redirect-to-app_w3era-tools-popup{
  @apply bg-pink text-white text-[14px] lg:text-[16px]  py-2 px-6 rounded-full font-medium
}

  .tool-analyzed{
    @apply transform pr-6 transition-transform  duration-300 group-hover:translate-x-2
  }
}

.seo_button{
  color: #08f;
}

.scrollbarHidden::-webkit-scrollbar {
  display: none; /* Hides scrollbar for WebKit browsers (Chrome, Safari) */
}

.scrollbarHidden {
  -ms-overflow-style: none; /* Hides scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hides scrollbar for Firefox */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

@layer utilities {
  .sticky-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    transition: box-shadow 0.3s ease;
  }

  .sticky-header.shadow {
    box-shadow: 0 2px 4px rgba(0 0 0 / 10%);
  }
}

.redes {
  width: 80px;
  height: auto;
  display: flex;
  justify-content: center;

  /* flex-direction: column; */
}

.redes a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  transition: all 0.2s ease-in-out;
}

.redes a svg {
  width: 30px;
  fill: #fff;
}

.redes .discord {
  background-color: rgb(0 60 255);
  border-radius: 0 0 10px ;
}

.redes .instagram {
  background: linear-gradient(45deg, #833ab4, #fd1d1d, #f56040);
  border-radius: 0 10px 0 0;
}

.redes .github {
  background-color: rgb(24 22 22);
}

.redes .twitter {
  background-color: #000;
}

.redes .facebook {
  background-color: #006aff;
}

.redes a:hover {
  width: 130%;
  border-radius: 0 10px 10px 0;
}

.category-bg{
  background-image: url('/blog/category-bg.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes zoomInOut {
  0% {
    rotate: 270deg;
    transform: scale(1);
  }

  50% {
    rotate: 270deg;
    transform: scale(1.1); /* Zoom in */
  }
  
  100% {
    rotate: 270deg;
    transform: scale(1); /* Zoom out */
  }
}

.zoom-in-out {
  animation: zoomInOut 2s ease-in-out infinite;
}

/* @theme {
  --3xl: 1700px;
} */

.bg-websiterank{
  background-image:url("/images/home-middle-banner.webp") ;
}

.ant-input{
  padding: 8px 11px;
}

 .ant-select-selector{
  padding: 18px 11px !important;
 }

 /* From Uiverse.io by joe-watson-sbf */ 
.flip-card {
  background-color: transparent;
  perspective: 1000px;
  font-family: sans-serif;
}

.title{
  text-align: center;
  margin: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  box-shadow: 0 8px 14px 0 rgb(0 0 0 / 20%);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid white;
  border-radius: 50%;
}
.flip-card-front {
  background: linear-gradient(120deg, white 30%, white 88%,
  white 40%, white 78%);
  color: #000;
  font-weight: bold;
  /* border:2px solid var(--white) */

}

.flip-card-back {
  background: linear-gradient(120deg, white 30%, white 88%,
  white 40%, white 78%);
  color: black;
  transform: rotateY(180deg);
  /* border:2px solid var(--pinkdarker) */
}

.shadow {
  box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, 
              rgb(17 17 26 / 10%) 0 8px 24px, 
              rgb(17 17 26 / 10%) 0 16px 56px;
}



.meetourteam-bg{
  background-image: url('/images/meetourteam-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative; 
}

.meetourteam-bg::before {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--blue);
  opacity: 0.75;
  z-index: 1;
}

.prose h2, 
.prose h3 { 
  scroll-margin-top: 6rem;
  line-height: 1.25;
}


@keyframes fadeIn {
  0% {
      top: 65%;
      right: 0;
  }
  15%{
    top: 55%;
    right: -5;
  }
  25%{
    top: 45%;
    right: -5;
  }
  50%{
    top: 35%;
    right: -5;
  }
  75%{
    top: 25%;
    right: -5%;
  }
  85%{
    top: 15%;
    right: -5%;
  }
  100% {
      top: 0%;
      right: 0%;
  }
}

/* Apply animation and background color on hover */
.outer-container:hover .fade-in {
  animation: fadeIn 0.15s ease-in-out forwards;
  /* background-color: chartreuse; */

  z-index: 900!important;
}

.shadow-inner {
  z-index: 60!;
  box-shadow: inset 0 0 50px rgba(2, 2, 2, 0.5); /* Adjust the shadow properties as needed */
}